import Vue from "vue";

export default {
    namespaced: true,
    state: {
        strategies: [],
        proxies: [],
        mobileProxies: [],
        myRooms: [],
    },
    actions: {
        constants({commit}) {
            return Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `data/constants`).then(resp => {
                commit('currencies', resp.data.result.currencies)
                commit('bookmakers', resp.data.result.bookmakers)
                commit('bk_mirrors', resp.data.result.bk_mirrors)
                commit('room_statuses', resp.data.result.room_statuses)
                commit('bet_modes', resp.data.result.bet_modes)
                commit('bet_types', resp.data.result.bet_types)
                commit('sports', resp.data.result.sports)
                commit('markets', resp.data.result.markets)
                commit('targets', resp.data.result.targets)
                commit('proxy_countries', resp.data.result.proxy_countries)
                commit('betexy_proxy_countries', resp.data.result.betexy_proxy_countries)
            }).catch(err => {
                Vue.prototype.flashMessage.show({
                    status: 'error',
                    title: 'Что-то пошло не так',
                    message: 'Не удалось получить данные'
                })
            })
        },
        strategies({commit}) {
            Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `data/strategies`).then(resp => {
                commit('strategies', resp.data.result.items)
            }).catch(err => {
                Vue.prototype.flashMessage.show({
                    status: 'error',
                    title: 'Что-то пошло не так',
                    message: 'Не удалось получить данные'
                })
            })
        },
        myRooms({commit}) {
            Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `data/my_rooms`).then(resp => {
                commit('myRooms', resp.data.result.items)
            }).catch(err => {
                Vue.prototype.flashMessage.show({
                    status: 'error',
                    title: 'Что-то пошло не так',
                    message: 'Не удалось получить данные'
                })
            })
        },
        proxies({commit}) {
            Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `data/proxies`).then(resp => {
                commit('proxies', resp.data.result.items)
                let mobiles = []
                resp.data.result.items.forEach((item) => {
                    if (item.is_mobile === 1) {
                        mobiles.push(item)
                    }
                })
                commit('mobileProxies', mobiles)
            }).catch(err => {
                Vue.prototype.flashMessage.show({
                    status: 'error',
                    title: 'Что-то пошло не так',
                    message: 'Не удалось получить данные'
                })
            })
        },
    },
    mutations: {
        pay_systems(state, systems) {
            localStorage.setItem('pay_systems', JSON.stringify(systems ? systems : []))
        },
        currencies(state, currencies) {
            localStorage.setItem('currencies', JSON.stringify(currencies ? currencies : []))
        },
        bookmakers(state, bks) {
            localStorage.setItem('bookmakers', JSON.stringify(bks ? bks : []))
        },
        bk_mirrors(state, mirrors) {
            localStorage.setItem('bk_mirrors', JSON.stringify(mirrors ? mirrors : []))
        },
        room_statuses(state, data) {
            localStorage.setItem('room_statuses', JSON.stringify(data ? data : []))
        },
        bet_modes(state, data) {
            localStorage.setItem('bet_modes', JSON.stringify(data ? data : []))
        },
        bet_types(state, data) {
            localStorage.setItem('bet_types', JSON.stringify(data ? data : []))
        },
        sports(state, data) {
            localStorage.setItem('sports', JSON.stringify(data ? data : []))
        },
        markets(state, data) {
            localStorage.setItem('markets', JSON.stringify(data ? data : []))
        },
        targets(state, data) {
            localStorage.setItem('targets', JSON.stringify(data ? data : []))
        },
        proxy_countries(state, data) {
            localStorage.setItem('proxy_countries', JSON.stringify(data ? data : []))
        },
        betexy_proxy_countries(state, data) {
            localStorage.setItem('betexy_proxy_countries', JSON.stringify(data ? data : []))
        },
        app_version(state, data) {
            localStorage.setItem('app_version', data)
        },
        strategies(state, data) {
            state.strategies = data
        },
        myRooms(state, data) {
            state.myRooms = data
        },
        proxies(state, data) {
            state.proxies = data
        },
        mobileProxies(state, data) {
            state.mobileProxies = data
        },
    },
    getters: {
        pay_systems(state) {
            return JSON.parse(localStorage.getItem('pay_systems'))
        },
        currencies(state) {
            return JSON.parse(localStorage.getItem('currencies'))
        },
        bookmakers(state) {
            return JSON.parse(localStorage.getItem('bookmakers'))
        },
        bk_mirrors(state) {
            return JSON.parse(localStorage.getItem('bk_mirrors'))
        },
        room_statuses(state) {
            return JSON.parse(localStorage.getItem('room_statuses'))
        },
        bet_modes(state) {
            return JSON.parse(localStorage.getItem('bet_modes'))
        },
        bet_types(state) {
            return JSON.parse(localStorage.getItem('bet_types'))
        },
        sports(state) {
            return JSON.parse(localStorage.getItem('sports'))
        },
        markets(state) {
            return JSON.parse(localStorage.getItem('markets'))
        },
        targets(state) {
            return JSON.parse(localStorage.getItem('targets'))
        },
        proxy_countries(state) {
            return JSON.parse(localStorage.getItem('proxy_countries'))
        },
        betexy_proxy_countries(state) {
            return JSON.parse(localStorage.getItem('betexy_proxy_countries'))
        },
        app_version(state) {
            return localStorage.getItem('app_version')
        },
        strategies(state) {
            return state.strategies
        },
        myRooms(state) {
            return state.myRooms
        },
        proxies(state) {
            return state.proxies
        },
        mobileProxies(state) {
            return state.mobileProxies
        },
    },
}