import Vue from 'vue'
import router from "@/router";

export default {
    namespaced: true,
    state: {
        user: localStorage.getItem('betexyuser'),
        btoken: localStorage.getItem('btoken'),
    },
    actions: {
        login({commit, dispatch}, payload) {
            return Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + 'auth/login', {user: payload})
                .then((resp) => {
                    commit('btoken', resp.data.result)
                    Vue.prototype.$http.defaults.headers.common['btoken'] = resp.data.result
                    dispatch('user_pulse')
                })
        },
        confirmation({commit, dispatch}, payload) {
            return Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + 'auth/confirmation', {user: payload})
                .then((resp) => {
                    commit('btoken', resp.data.result)
                    Vue.prototype.$http.defaults.headers.common['btoken'] = resp.data.result
                    dispatch('user_pulse')
                })
        },
        logout({commit}, redirect = true) {
            delete Vue.prototype.$http.defaults.headers.common['btoken']
            commit('btoken', null)
            commit('user', null)
            if (redirect) {
                router.push('/login');
            }
        },
        user_pulse({commit, getters, rootGetters}) {
            if (getters.btoken) {
                return Vue.prototype.$http.get(process.env.VUE_APP_API_HOST + `profile/me`).then(resp => {
                    if (resp.data.result.iface_v > rootGetters["data/app_version"]) {
                        console.log(resp.data.result.iface_v + ' > ' + rootGetters['data/app_version']);
                        location.reload()
                    }
                    let user = resp.data.result.user
                    user.notifications = resp.data.result.notifications
                    user.dashboard = resp.data.result.dashboard
                    commit('user', user)
                })
            }
        },
    },
    mutations: {
        user(state, user) {
            localStorage.setItem('betexyuser', user ? JSON.stringify(user) : null)
            state.user = localStorage.getItem('betexyuser')
        },
        btoken(state, btoken) {
            localStorage.setItem('btoken', btoken)
            state.btoken = localStorage.getItem('btoken')
        },
    },
    getters: {
        user(state) {
            return state.user ? JSON.parse(localStorage.getItem('betexyuser')) : null;
        },
        btoken(state) {
            return state.btoken;
        },
    },
}