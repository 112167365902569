<template>
  <b-navbar fixed-top shadow transparent spaced type="is-light">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img src="../assets/logo_s.svg">
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" :to="{ path: '/dashboard' }">
        <b-icon icon="view-dashboard" size="is-small"/>
        <span>{{ $ml.get('menu_dashboard') }}</span>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/rooms' }">
        <b-icon icon="account" size="is-small"/>
        <span>{{ $ml.get('menu_accounts') }}</span>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/public-strategies' }">
        <b-icon icon="briefcase" size="is-small"/>
        <span>{{ $ml.get('menu_strategies') }}</span>
      </b-navbar-item>
<!--      <b-navbar-item tag="router-link" :to="{ path: '/proxies' }">-->
<!--        <b-icon icon="earth" size="is-small"/>-->
<!--        <span>{{ $ml.get('menu_proxy') }}</span>-->
<!--      </b-navbar-item>-->
      <b-navbar-item tag="router-link" :to="{ path: '/bets/placed' }">
        <b-icon icon="star" size="is-small"/>
        <span>{{ $ml.get('menu_bets') }}</span>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/statistic/user' }">
        <b-icon icon="chart-bar" size="is-small"/>
        <span>{{ $ml.get('menu_statistic') }}</span>
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/pages/help' }">
        <b-icon icon="help-circle" size="is-small"/>
        <span>{{ $ml.get('menu_help') }}</span>
      </b-navbar-item>
    </template>

    <template slot="end">
      <b-navbar-item tag="router-link" :to="{ path: '/payment' }"
                     :class="this.$store.getters['auth/user'].balance < 10 ? 'text-danger' : 'text-success'">
        <b-icon icon="wallet" size="is-small"/>
        <Money v-bind:amount="this.$store.getters['auth/user'].balance" currency="EUR"></Money>
      </b-navbar-item>
      <b-navbar-item @click="logout">
        <b-icon icon="logout" size="is-small"/>
        <span>{{ $ml.get('menu_logout') }} {{ this.$store.getters['auth/user'].email }}</span>
      </b-navbar-item>
      <b-navbar-item>
        <a @click="$ml.change('ru')" title="Русский" style="margin-right: 5px">
          <img src="../assets/ru.png">
        </a>
        <a @click="$ml.change('en')" title="English">
          <img src="../assets/en.png">
        </a>
      </b-navbar-item>
      <b-navbar-item>
        <a href="https://t.me/betexychat" target="_blank" :title="$ml.get('menu_telegram')">
          <img src="../assets/tg.svg">
        </a>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import Money from "@/components/Money";

export default {
  name: "Navbar",
  data() {
    return {
      pulse: null,
      constants: null,
    }
  },
  components: {Money},
  methods: {
    logout: function () {
      this.$store.dispatch('auth/logout')
    },
  },
  mounted() {
    this.$store.dispatch('auth/user_pulse')
    this.pulse = setInterval(() => {
      this.$store.dispatch('auth/user_pulse')
    }, 90000)
    this.constants = setInterval(() => {
      this.$store.dispatch('data/constants')
    }, 3600000)
  },
  beforeDestroy() {
    clearInterval(this.pulse)
    this.pulse = null
    clearInterval(this.constants)
    this.constants = null
  },
}
</script>

<style scoped>
img {
  width: 30px;
  height: 30px;
}
</style>