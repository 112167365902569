import Vue from 'vue'
import { MLInstaller, MLCreate, MLanguage } from 'vue-multilanguage'

Vue.use(MLInstaller)
if (!localStorage.getItem('vueml-lang')) {
    localStorage.setItem('vueml-lang', 'en')
}

export default new MLCreate({
    initial: 'en',
    save: true,
    languages: [
        new MLanguage('en').create({
            titles_bets_placed: 'Bets',
            titles_bets_settled: 'Settled bets',
            titles_bets_strategy: 'Strategy bets',
            titles_payment: 'Deposit',
            titles_proxies_index: 'Proxies',
            titles_proxies_buy: 'Buy proxy',
            titles_pages_help: 'Help',
            titles_pages_successful_deposit: 'Successful deposit!',
            titles_pages_failed_deposit: 'Deposit failed :(',
            titles_accounts: 'Accounts',
            titles_accounts_new_account: 'New account',
            titles_statistic: 'Statistic',
            menu_dashboard: 'Dashboard',
            menu_accounts: 'Accounts',
            menu_strategies: 'Strategies',
            menu_proxy: 'Proxy',
            menu_bets: 'Bets',
            menu_statistic: 'Statistic',
            menu_help: 'Help',
            menu_logout: 'Logout',
            menu_telegram: 'Telegram channel',
            login_password: 'Password',
            login_signup: 'Signup',
            login_forgotpass: 'Forgot password',
            login_login: 'Sign in',
            login_confirmation_text: 'Please check your email and follow instructions',
            login_reset_text: 'Please check your email and follow instructions',
            login_newpass_text: 'Please sign in with your new password',
            newpass_new_password: 'New password',
            newpass_new_password_message: 'Latin letters, digits, symbols. Minimum 6 characters',
            newpass_confirm_password: 'Confirm password',
            newpass_set_password: 'Set password',
            reset_password: 'Reset password',
            signup_do_signup: 'Signup',
            index_graph_head: '2 months bet nominal graph',
            index_graph_head_note: 'Multiply it with your stake and get the profit',
            index_graph_select_total: 'Summary',
            index_last_bets: 'Current bets',
            index_no_open_bets: 'No open bets at the moment',
            dashboard_bets_count: 'BETS COUNT',
            dashboard_turnover: 'TURNOVER',
            dashboard_profit: 'PROFIT',
            dashboard_today_title: 'Today',
            dashboard_accounts: 'Accounts',
            dashboard_no_notifications: 'You have no notifications',
            common_request_failed: 'Request failed',
            common_request_failed_message: 'An error has been occurred',
            common_request_success: 'Request successful',
            common_request_success_data_message: 'Data saved',
            common_from: 'From',
            common_to: 'To',
            common_show: 'Show',
            common_month_short: 'month',
            common_edit: 'Edit',
            common_delete: 'Delete',
            common_save: 'Save',
            common_create: 'Create',
            common_statistic: 'Statistic',
            common_send: 'Send',
            common_close: 'Close',
            common_monday: 'Monday',
            common_tuesday: 'Tuesday',
            common_wednesday: 'Wednesday',
            common_thursday: 'Thursday',
            common_friday: 'Friday',
            common_saturday: 'Saturday',
            common_sunday: 'Sunday',
            common_confirm: 'Are you sure?',
            payment_deposit: 'Deposit',
            payment_amount: 'Euro amount',
            payment_amount_range: 'Min 20 euro',
            payment_make_deposit: 'Deposit',
            bets_placed_head: 'Bets',
            bets_settled_head: 'Settled bets',
            bets_settled_amount: 'Amount',
            bets_mode: 'Mode',
            bets_account: 'Account',
            bets_strategy: 'Strategy',
            bets_status: 'Status',
            bets_coef: 'Coef',
            bets_stake: 'Stake',
            bets_bookmaker: 'Bookmaker',
            bets_time: 'Time',
            bets_event_line: 'Event and line',
            bets_placed: 'Placed',
            bets_market: 'Market',
            bets_target: 'Target',
            bets_sport: 'Sport',
            bets_date: 'Date',
            bets_result: 'Result',
            bets_strategy_title: 'Strategy {0} bets',
            bets_count: 'Count',
            pages_successful_deposit_head: 'Successful deposit, thank you!',
            pages_successful_deposit_text: 'Your balance will be updated once we got payment confirmation!',
            pages_failed_deposit_head: 'Deposit failed :(',
            pages_failed_deposit_text: '...',
            proxies_proxy: 'Proxy',
            proxies_buy_proxy_btn: 'Buy proxy',
            proxies_create_own_btn: 'Add your own',
            proxies_mark: 'Mark',
            proxies_country: 'Country',
            proxies_accounts_count: 'Accounts',
            proxies_expired_at: 'Expired through',
            proxies_auto_renew: 'Auto renewal',
            proxies_renew_link: 'renew for 30 days',
            proxies_renew_confirm: 'Are you sure you want to renew proxy?',
            proxies_delete_confirm: 'Attention! Accounts uses this proxy will be stopped!',
            proxies_mark_example: 'Example: Proxy for Johnny',
            proxies_buy_for: 'Buy for',
            proxies_buy_confirm: 'Buy proxy?',
            proxies_new_proxy: 'New proxy',
            proxies_proto: 'Protocol',
            proxies_host: 'Host',
            proxies_port: 'Port',
            proxies_login: 'Login',
            proxies_password: 'Password',
            proxies_is_mobile: 'Mobile proxy (for BET365)',
            accounts_account: 'Account',
            accounts_name: 'Name',
            accounts_bookmaker: 'Bookmaker',
            accounts_state: 'State',
            accounts_balance: 'Balance',
            accounts_placed_balance: 'Placed',
            accounts_placed_bets: 'Placed bets',
            accounts_last_bet: 'Last bet',
            accounts_code_link: 'enter code',
            accounts_rdp_link: 'Download',
            accounts_buy_link_7: 'Buy/Prolong subscription for 7 days',
            accounts_buy_confirm_7: '7 days subscription cost is 20 EUR. Buy it?',
            accounts_buy_link_30: 'Buy/Prolong subscription for 30 days',
            accounts_buy_confirm_30: '30 days subscription cost is 65 EUR. Buy it?',
            accounts_buy_success: 'Subscription activated! You can download files now! Thank you!',
            accounts_start_link: 'Start',
            accounts_stop_link: 'Stop',
            accounts_rdp_link_prefix: 'Connection link',
            accounts_rdp_login: 'Your login',
            accounts_rdp_password: 'Your password',
            accounts_rdp_close: 'Close connection',
            accounts_rdp_await_title: 'Establishing connection...',
            accounts_rdp_await_text: 'Please wait a bit...',
            accounts_code_title: 'Confirmation code (sms)',
            accounts_start_confirm: 'Start account?',
            accounts_stop_confirm: 'Stop account?',
            accounts_delete_confirm: 'Delete account?',
            accounts_rdp_confirm: 'Open connection? If account is running it will be automatically stopped',
            accounts_rdp_done_title: 'Connection established',
            accounts_rdp_done_text: '',
            accounts_rdp_done_text2: '',
            accounts_name_placeholder: 'Any name you want',
            accounts_name_example: 'Example: Agent-Smith-OLIMP',
            accounts_bookmaker_placeholder: 'Account\'s bookmaker',
            accounts_currency: 'Currency',
            accounts_currency_placeholder: 'Betting currency',
            accounts_proxy: 'Proxy',
            accounts_proxy_placeholder: 'Must be mobile proxy for BET365',
            accounts_login: 'Login',
            accounts_login_placeholder: 'Must me phone number for 1XSTAVKA, PINUP.CUPIS and OLIMP.CUPIS',
            accounts_password: 'Password',
            accounts_password_placeholder: 'Account password',
            accounts_comment: 'Comment',
            accounts_comment_placeholder: 'Any information you need to save',
            accounts_settings: 'Settings',
            accounts_deposit_balance: 'Deposit balance',
            accounts_ends_at: 'Active until',
            accounts_withdraw_balance: 'Withdrawal balance',
            accounts_deposit_balance_placeholder: 'You will be notified that account needs deposit when it reaches this balance',
            accounts_withdraw_balance_placeholder: 'You will be notified that account needs withdrawal when it reaches this balance',
            accounts_max_bets: 'Max bets count',
            accounts_max_bets_placeholder: 'Per event',
            accounts_bet_interval: 'Betting interval (sec)',
            accounts_bet_interval_placeholder: 'Min 10',
            accounts_min_coef: 'Min coef',
            accounts_max_coef: 'Max coef',
            accounts_fulltime_only: 'Bet Full-time only',
            accounts_bet_junior: 'Don\'t bet on junior teams',
            accounts_bet_woman: 'Don\'t bet on women teams',
            accounts_leagues_include_bet_all: 'Bet all leagues',
            accounts_leagues_include_include: 'Bet chosen only',
            accounts_leagues_include_exclude: 'Don\'t bet chosen',
            accounts_sports: 'Sports',
            accounts_markets: 'Markets',
            accounts_strategies: 'Strategies',
            accounts_strategies_info: 'Maximum 5 strategies can be added. We recommend to set stake as 1/25 of account balance',
            accounts_strategy: 'Strategy',
            accounts_stake: 'Stake',
            accounts_dynamic_stake: 'Dynamic stake',
            accounts_balance_percent: 'Percent of balance',
            accounts_add_strategy: 'Add strategy',
            accounts_schedule: 'Schedule',
            accounts_schedule_info: 'Schedule generated based on added strategies. Timezone +7 GMT',
            accounts_timetable_manual: 'Manual mode',
            accounts_timetable_manual_info: 'Attention! If schedule of strategy you choose will be changed by owner or you add/remove another strategy you must change the schedule manually!',
            accounts_timetable_manual_copy: 'Load settings:',
            accounts_timetable_reset: 'Reset schedule',
            accounts_schedule_manual_off_confirm: 'Are you sure? All changes will be reset',
            accounts_enable_sport: 'Enable sport',
            statistic_room: 'Account {0} statistic',
            statistic_strategy: 'Strategy {0} statistic',
            statistic_date: 'Date',
            statistic_bets_count: 'Bets',
            statistic_turnover: 'Turnover',
            statistic_profit: 'Profit',
            statistic_user_common: 'Common',
            statistic_referral_profit: 'Referral',
            statistic_payments: 'Payments',
            statistic_referrals: 'Referrals',
            statistic_referral_registered: 'Date',
            statistic_amount: 'Amount',
            statistic_no_payments: 'You have no payments yet',
            statistic_referral_disabled: 'Referral system does not available for you yet',
            statistic_referral_link: 'Your referral link',
            statistic_referral_percent: 'Your referral fee',
            statistic_referral_limit: 'Your referrals limit',
            statistic_referral_turnover: 'of each deposit',
            statistic_referral_profit_sum: 'Summary profit',
            statistic_referral_payouts: 'Payouts',
            statistic_referral: 'Referral',
            titles_strategies_public: 'Public strategies',
            titles_strategies: 'Strategies',
            strategies_strategy: 'Strategy',
            strategies_my_strategies: 'My strategies',
            strategies_public_notice: 'Strategy commission does not include basic service fee 1%',
            strategies_risk: 'Risk level',
            strategies_fee: 'Commission',
            strategies_users: 'Users',
            strategies_bets_count: 'Bets',
            strategies_turnover: 'Turnover',
            strategies_placed_bets: 'Placed bets',
            strategies_mode: 'Mode',
            strategies_mode_public: 'Public',
            strategies_mode_private: 'Private',
            strategies_mode_limited: 'Limited',
            strategies_delete_confirm: 'Delete strategy?',
            titles_strategies_new: 'New strategy',
            strategies_name: 'Name',
            strategies_name_placeholder: 'Short understandable name',
            strategies_fee_placeholder: 'Your % of turnover (from 0 to 2). Can be decimal',
            strategies_risk_placeholder: 'From 1 to 6. Must be set for public strategy',
            strategies_public_placeholder: 'Temporary disabled',
            strategies_private_placeholder: 'Available for you only',
            strategies_limited_placeholder: 'Available for you and for users you chose',
            strategies_limited_users: 'Comma separated Betexy user emails who you want to share your strategy with',
            strategies_info: 'Description',
            strategies_schedule: 'Schedule (green - on / red - off)',
            strategies_create_email_sent: 'Please check your email for secret strategy key',
            payment_limited_head: 'USDT deposit',
            payment_limited_text: 'Send USDT TRC20 to TQEsXL62K372ff2oNUkFd5XKeFBZDi7Wou wallet and contact our manager telegram @bcp13 or @bmvv503',
            proxies_not365: 'These proxies is not for BET365',
            proxies_period: 'Period',
            proxies_no_available_title: 'Could not buy proxy',
            proxies_no_available_text: 'There is no proxy available for chosen country. Please try later',
            proxies_expired: 'expired',
            accounts_bk_mirror: 'Mirror',
            FOOTBALL: 'Soccer',
            TENNIS: 'Tennis',
            HOCKEY: 'Hockey',
            BASKETBALL: 'Basketball',
            VOLLEYBALL: 'Volleyball',
            HANDBALL: 'Handball',
            BASEBALL: 'Baseball',
            TABLETENNIS: 'Table tennis',
            CYBERSPORT: 'Cybersport',
            ONE_TWO: '1X2',
            TOTAL: 'Total',
            T1_TOTAL: 'Total (Home)',
            T2_TOTAL: 'Total (Away)',
            YC_TOTAL: 'Total (YC)',
            CORNER_TOTAL: 'Total (Corners)',
            HDP: 'Hdp',
            YC_HDP: 'Hdp (YC)',
            CORNER_HDP: 'Hdp (Corners)',
            EURO_HDP: 'Hdp (Euro)',
            ONE_TWO__ONE: '1',
            ONE_TWO__TWO: '2',
            ONE_TWO__ONE_TWO: '12',
            ONE_TWO__DRAW: 'X',
            ONE_TWO__ONE_DRAW: '1X',
            ONE_TWO__TWO_DRAW: 'X2',
            TOTAL__OVER: 'Over',
            TOTAL__UNDER: 'Under',
            T1_TOTAL__OVER: 'Over',
            T1_TOTAL__UNDER: 'Under',
            T2_TOTAL__OVER: 'Over',
            T2_TOTAL__UNDER: 'Under',
            YC_TOTAL__OVER: 'Over',
            YC_TOTAL__UNDER: 'Under',
            CORNER_TOTAL__OVER: 'Over',
            CORNER_TOTAL__UNDER: 'Under',
            HDP__HOME: 'W1',
            HDP__AWAY: 'W2',
            CORNER_HDP__HOME: 'W1',
            CORNER_HDP__AWAY: 'W2',
            YC_HDP__HOME: 'W1',
            YC_HDP__AWAY: 'W2',
            EURO_HDP__H1: 'W1',
            EURO_HDP__H2: 'W2',
            EURO_HDP__HX: 'X',
        }),

        new MLanguage('ru').create({
            titles_bets_placed: 'Ставки',
            titles_bets_settled: 'Закрытые ставки',
            titles_bets_strategy: 'Ставки по стратегии',
            titles_payment: 'Пополнить баланс',
            titles_proxies_index: 'Прокси',
            titles_proxies_buy: 'Купить прокси',
            titles_pages_help: 'Помощь',
            titles_pages_failed_deposit: 'Баланс не пополнен :(',
            titles_accounts: 'Аккаунты',
            titles_accounts_new_account: 'Новый аккаунт',
            titles_statistic: 'Статистика',
            menu_dashboard: 'Дашборд',
            menu_accounts: 'Аккаунты',
            menu_strategies: 'Стратегии',
            menu_proxy: 'Прокси',
            menu_bets: 'Ставки',
            menu_statistic: 'Статистика',
            menu_help: 'Помощь',
            menu_logout: 'Выйти',
            menu_telegram: 'Телеграм канал',
            login_password: 'Пароль',
            login_signup: 'Регистрация',
            login_forgotpass: 'Забыл пароль',
            login_login: 'Войти',
            login_confirmation_text: 'На указанный при регистрации емейл было выслано письмо со ссылкой на подтверждение аккаунта',
            login_reset_text: 'На указанный емейл было выслано письмо с инструкциями по сбросу пароля',
            login_newpass_text: 'Войдите, используя новый пароль',
            newpass_new_password: 'Новый пароль',
            newpass_new_password_message: 'Спецсимволы, буквы и цифры латинского алфавита. Минимум 6 знаков',
            newpass_confirm_password: 'Подтверждение пароля',
            newpass_set_password: 'Установить пароль',
            reset_password: 'Сбросить пароль',
            signup_do_signup: 'Зарегистрироваться',
            index_graph_head: 'График по номиналам ставки за 2 месяца',
            index_graph_head_note: 'Умножьте цифру на графике на вашу сумму ставки и получите прибыль за период',
            index_graph_select_total: 'Общий',
            index_last_bets: 'Последние ставки',
            index_no_open_bets: 'На данный момент открытых ставок нет',
            dashboard_bets_count: 'КОЛИЧЕСТВО СТАВОК',
            dashboard_turnover: 'ОБОРОТ',
            dashboard_profit: 'ПРОФИТ',
            dashboard_today_title: 'За сегодня',
            dashboard_accounts: 'Аккаунты',
            dashboard_no_notifications: 'У вас пока нет нотификаций',
            common_request_failed: 'Не удалось выполнить запрос',
            common_request_failed_message: 'Возникла ошибка',
            common_request_success: 'Запрос выполнен',
            common_request_success_data_message: 'Данные сохранены',
            common_from: 'С',
            common_to: 'По',
            common_show: 'Показать',
            common_month_short: 'мес',
            common_edit: 'Редактировать',
            common_delete: 'Удалить',
            common_save: 'Сохранить',
            common_create: 'Создать',
            common_statistic: 'Статистика',
            common_send: 'Отправить',
            common_close: 'Закрыть',
            common_monday: 'Понедельник',
            common_tuesday: 'Вторник',
            common_wednesday: 'Среда',
            common_thursday: 'Четверг',
            common_friday: 'Пятница',
            common_saturday: 'Суббота',
            common_sunday: 'Воскресенье',
            common_confirm: 'Вы уверены?',
            payment_deposit: 'Пополнить баланс',
            payment_amount: 'Сумма в евро',
            payment_amount_range: 'минимум 20 евро',
            payment_make_deposit: 'Пополнить',
            bets_placed_head: 'Открытые ставки',
            bets_settled_head: 'Закрытые ставки',
            bets_settled_amount: 'На сумму',
            bets_mode: 'Режим',
            bets_account: 'Аккаунт',
            bets_strategy: 'Стратегия',
            bets_status: 'Статус',
            bets_coef: 'Кэф',
            bets_stake: 'Сумма',
            bets_bookmaker: 'Контора',
            bets_time: 'Тайм',
            bets_event_line: 'Событие и линия',
            bets_placed: 'Поставлена',
            bets_market: 'Рынок',
            bets_target: 'Таргет',
            bets_sport: 'Sport',
            bets_date: 'Дата',
            bets_result: 'Результат',
            bets_strategy_title: 'Открытые ставки по стратегии {0}',
            bets_count: 'Ставок',
            pages_successful_deposit_head: 'Спасибо, Ваш перевод принят!',
            pages_successful_deposit_text: 'Мы зачислим средства на баланс, как только получим подтверждение оплаты!',
            pages_failed_deposit_head: 'Баланс не пополнен :(',
            pages_failed_deposit_text: '...',
            proxies_proxy: 'Прокси',
            proxies_buy_proxy_btn: 'Купить прокси',
            proxies_create_own_btn: 'Добавить свою',
            proxies_mark: 'Метка',
            proxies_country: 'Страна',
            accounts_ends_at: 'Подписка до',
            proxies_accounts_count: 'Аккаунтов',
            proxies_expired_at: 'Истекает через',
            proxies_auto_renew: 'Автопродление',
            proxies_renew_link: 'продлить на 30 дней',
            proxies_renew_confirm: 'Вы уверены, что хотите продлить прокси?',
            proxies_delete_confirm: 'Внимание! Все аккаунты, которые используют эту прокси, будут остановлены! Удалить прокси?',
            proxies_mark_example: 'Например: Прокси для Ивана',
            proxies_buy_for: 'Купить прокси за',
            proxies_buy_confirm: 'Купить прокси?',
            proxies_new_proxy: 'Новая прокси',
            proxies_proto: 'Протокол',
            proxies_host: 'Хост',
            proxies_port: 'Порт',
            proxies_login: 'Логин',
            proxies_password: 'Пароль',
            proxies_is_mobile: 'Мобильная прокси (для BET365)',
            accounts_account: 'Аккаунт',
            accounts_name: 'Название',
            accounts_bookmaker: 'Контора',
            accounts_state: 'Статус',
            accounts_balance: 'Баланс',
            accounts_placed_balance: 'В ставках',
            accounts_placed_bets: 'Откр.Ставок',
            accounts_last_bet: 'Посл.Ставка',
            accounts_buy_success: 'Подписка активирована! Спасибо! Теперь вы можете скачать файлы!',
            accounts_code_link: 'ввести код',
            accounts_rdp_link: 'Скачать',
            accounts_buy_link_7: 'Купить/Продлить подписку на 7 дней',
            accounts_buy_confirm_7: 'Стоимость подписки на 7 дней: 20 евро. Купить подписку?',
            accounts_buy_link_30: 'Купить/Продлить подписку на 30 дней',
            accounts_buy_confirm_30: 'Стоимость подписки на 30 дней: 65 евро. Купить подписку?',
            accounts_start_link: 'Запустить',
            accounts_stop_link: 'Остановить',
            accounts_rdp_link_prefix: 'Ссылка на соединение',
            accounts_rdp_login: 'Ваш логин',
            accounts_rdp_password: 'Ваш пароль',
            accounts_rdp_close: 'Завершить сеанс',
            accounts_rdp_await_title: 'Открываем соединение...',
            accounts_rdp_await_text: 'Пожалуйста, подождите...',
            accounts_code_title: 'Код авторизации (смс)',
            accounts_start_confirm: 'Запустить аккаунт?',
            accounts_stop_confirm: 'Остановить аккаунт?',
            accounts_delete_confirm: 'Удалить аккаунт?',
            accounts_rdp_confirm: 'Открыть соединение? В случае, если аккаунт запущен - он будет принудительно остановлен',
            accounts_rdp_done_title: 'Файл готов',
            accounts_rdp_done_text: '',
            accounts_rdp_done_text2: '',
            accounts_name_placeholder: 'Любое название в свободной форме',
            accounts_name_example: 'Например: Агент-Смит-OLIMP',
            accounts_bookmaker_placeholder: 'БК, где зарегистрирован аккаунт',
            accounts_currency: 'Валюта',
            accounts_currency_placeholder: 'Валюта, в которой ставим',
            accounts_proxy: 'Прокси',
            accounts_proxy_placeholder: 'BET365 работает только с мобильными прокси',
            accounts_login: 'Логин',
            accounts_login_placeholder: 'Для 1XSTAVKA, PINUP.CUPIS и OLIMP.CUPIS - номер телефона в формате +7xxx...',
            accounts_password: 'Пароль',
            accounts_password_placeholder: 'Пароль от аккаунта в букмекере',
            accounts_comment: 'Информация',
            accounts_comment_placeholder: 'Любое описание в свободной форме. Например, какие-нибудь важные данные аккаунта, чтобы не забыть',
            accounts_settings: 'Настройки',
            accounts_deposit_balance: 'Баланс депозита',
            accounts_withdraw_balance: 'Баланс вывода',
            accounts_deposit_balance_placeholder: 'Баланс аккаунта, по достижении которого, будет отправлена нотификация о необходимости пополнения',
            accounts_withdraw_balance_placeholder: 'Баланс аккаунта, по достижении которого, будет отправлена нотификация о необходимости вывода',
            accounts_max_bets: 'Макс. ставок',
            accounts_max_bets_placeholder: 'На одно событие',
            accounts_bet_interval: 'Интервал ставок (сек)',
            accounts_bet_interval_placeholder: 'Не меньше 10',
            accounts_min_coef: 'Кэф от',
            accounts_max_coef: 'Кэф до',
            accounts_fulltime_only: 'Ставить только фулл-тайм',
            accounts_bet_junior: 'Не ставить на молодежные команды',
            accounts_bet_woman: 'Не ставить на женские команды',
            accounts_leagues_include_bet_all: 'Ставить на все чемпионаты',
            accounts_leagues_include_include: 'Ставить только на выбранные',
            accounts_leagues_include_exclude: 'Не ставить на выбранные',
            accounts_sports: 'Спорты',
            accounts_enable_sport: 'Включить спорт',
            accounts_markets: 'Маркеты',
            accounts_strategies: 'Стратегии',
            accounts_strategies_info: 'Максимум можно подключить 5 стратегий. Для статической суммы ставки рекомендуем ставить 1/25 от баланса аккаунта',
            accounts_strategy: 'Стратегия',
            accounts_stake: 'Сумма ставки',
            accounts_dynamic_stake: 'Динамическая сумма',
            accounts_balance_percent: 'Процент от баланса',
            accounts_add_strategy: 'Добавить стратегию',
            accounts_schedule: 'Расписание',
            accounts_schedule_info: 'Расписание формируется на основе выбранных стратегий. Часовой пояс расписания +7 GMT',
            accounts_timetable_manual: 'Ручной режим',
            accounts_timetable_manual_info: 'Внимание! Если у выбранных стратегий изменится расписание или вы добавите/удалите стратегию - то расписание нужно будет изменять вручную!',
            accounts_timetable_manual_copy: 'Загрузить настройки:',
            accounts_timetable_reset: 'Сбросить изменения',
            accounts_schedule_manual_off_confirm: 'Вы уверены? Все изменения будут утеряны',
            statistic_room: 'Статистика аккаунта {0}',
            statistic_strategy: 'Статистика стратегии {0}',
            statistic_date: 'Дата',
            statistic_bets_count: 'Ставок',
            statistic_turnover: 'Оборот',
            statistic_profit: 'Профит',
            statistic_user_common: 'Общая',
            statistic_referral_profit: 'Рефералка',
            statistic_payments: 'Платежи',
            statistic_referrals: 'Рефералы',
            statistic_referral_registered: 'Дата',
            statistic_amount: 'Сумма',
            statistic_no_payments: 'У вас пока нет платежей',
            statistic_referral_disabled: 'Вам пока недоступна реферальная система',
            statistic_referral_link: 'Ваша реф. ссылка',
            statistic_referral_percent: 'Реферальный доход',
            statistic_referral_limit: 'Макс. рефералов',
            statistic_referral_turnover: 'с каждого пополнения',
            statistic_referral_profit_sum: 'Суммарный доход',
            statistic_referral_payouts: 'Выплаты',
            statistic_referral: 'Реферал',
            titles_strategies_public: 'Публичные стратегии',
            titles_strategies: 'Стратегии',
            strategies_strategy: 'Стратегия',
            strategies_my_strategies: 'Мои стратегии',
            strategies_public_notice: 'Комиссия стратегии складывается с общей комиссией сервиса 1%',
            strategies_risk: 'Степень риска',
            strategies_fee: 'Комиссия',
            strategies_users: 'Подписчиков',
            strategies_bets_count: 'Ставок',
            strategies_turnover: 'Оборот',
            strategies_placed_bets: 'Открытые ставки',
            strategies_mode: 'Режим',
            strategies_mode_public: 'Публичная',
            strategies_mode_private: 'Приватная',
            strategies_mode_limited: 'Закрытая',
            strategies_delete_confirm: 'Удалить стратегию?',
            titles_strategies_new: 'Новая стратегия',
            strategies_name: 'Название',
            strategies_name_placeholder: 'Короткое название, отражающее суть',
            strategies_fee_placeholder: 'Ваш % с оборота (от 0 до 2). Может быть дробным',
            strategies_risk_placeholder: 'От 1 до 6. Обязательна для публичной стратегии',
            strategies_public_placeholder: 'В данный момент опция недоступна',
            strategies_private_placeholder: 'Стратегия доступна только вам',
            strategies_limited_placeholder: 'Стратегия доступна вам и выбранным вами пользователям',
            strategies_limited_users: 'Емейлы пользователей Betexy через запятую, кому будет доступна стратегия',
            strategies_info: 'Описание стратегии',
            strategies_schedule: 'График работы (зеленая - вкл, красная - выкл)',
            strategies_create_email_sent: 'На вашу электронную почту отправлен секретный ключ стратегии. Не сообщайте его никому!',
            payment_limited_head: 'Пополнение через USDT',
            payment_limited_text: 'Чтобы пополнить баланс, переведите USDT TRC20 на адрес TQEsXL62K372ff2oNUkFd5XKeFBZDi7Wou, после чего свяжитесь с нашими менеджерами в Telegram: @bcp13 или @bmvv503 и отправьте ссылку на транзакцию или скриншот перевода. Это временная мера, мы уже работаем над интеграцией автоматического приема USDT переводов. Приносим извинения за неудобства.',
            proxies_not365: 'Данные прокси не подходят для BET365',
            proxies_period: 'Период',
            proxies_no_available_title: 'Не удалось купить прокси',
            proxies_no_available_text: 'На данный момент нет доступных прокси для выбранной страны. Пожалуйста, попробуйте позже',
            proxies_expired: 'истекла',
            accounts_bk_mirror: 'Зеркало',
            FOOTBALL: 'Футбол',
            TENNIS: 'Теннис',
            HOCKEY: 'Хоккей',
            BASKETBALL: 'Баскетбол',
            VOLLEYBALL: 'Волейбол',
            HANDBALL: 'Гандбол',
            BASEBALL: 'Бейсбол',
            TABLETENNIS: 'Настольный теннис',
            CYBERSPORT: 'Киберспорт',
            ONE_TWO: '1X2',
            TOTAL: 'Тотал',
            T1_TOTAL: 'Тотал (Home)',
            T2_TOTAL: 'Тотал (Away)',
            YC_TOTAL: 'Тотал (ЖК)',
            CORNER_TOTAL: 'Тотал (Угл)',
            HDP: 'Форы',
            YC_HDP: 'Форы (ЖК)',
            CORNER_HDP: 'Форы (Угл)',
            EURO_HDP: 'Форы (Евро)',
            ONE_TWO__ONE: '1',
            ONE_TWO__TWO: '2',
            ONE_TWO__ONE_TWO: '12',
            ONE_TWO__DRAW: 'X',
            ONE_TWO__ONE_DRAW: '1X',
            ONE_TWO__TWO_DRAW: 'X2',
            TOTAL__OVER: 'ТБ',
            TOTAL__UNDER: 'ТМ',
            T1_TOTAL__OVER: 'ТБ',
            T1_TOTAL__UNDER: 'ТМ',
            T2_TOTAL__OVER: 'ТБ',
            T2_TOTAL__UNDER: 'ТМ',
            YC_TOTAL__OVER: 'ТБ',
            YC_TOTAL__UNDER: 'ТМ',
            CORNER_TOTAL__OVER: 'ТБ',
            CORNER_TOTAL__UNDER: 'ТМ',
            HDP__HOME: 'П1',
            HDP__AWAY: 'П2',
            CORNER_HDP__HOME: 'П1',
            CORNER_HDP__AWAY: 'П2',
            YC_HDP__HOME: 'П1',
            YC_HDP__AWAY: 'П2',
            EURO_HDP__H1: 'П1',
            EURO_HDP__H2: 'П2',
            EURO_HDP__HX: 'X',
        })
    ]
})