<template>
  <div id="app">
    <div class="container is-fluid" style="margin-bottom: 20px">
      <Navbar v-if="this.$store.getters['auth/user']"/>
    </div>
    <FlashMessage :position="'right top'"/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: 'App',
  components: {
    Navbar
  },
  metaInfo: {
    title: 'BETEXY',
  }
}
</script>

<style>
@font-face {
  font-family: 'Jura';
  font-style: normal;
  font-weight: 400;
  src: url(../public/fonts/Jura-cyr.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

@font-face {
  font-family: 'Jura';
  font-style: normal;
  font-weight: 400;
  src: url(../public/fonts/Jura-lat.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

#app {
  height: 100% !important;
  font-family: 'Jura', sans-serif !important;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/*.navbar-item i {*/
/*  color: rgb(204, 51, 51);*/
/*}*/

.label {
  font-size: 14px !important;
  font-weight: normal !important;
}

.tag.is-success, .button.is-success {
  background-color: rgba(72, 199, 116, 0.5) !important;
  color: inherit !important;
}

.tag.is-warning, .button.is-warning {
  background-color: rgba(255, 221, 87, 0.5) !important;
  color: inherit !important;
}

.tag.is-alert, .button.is-alert {
  background-color: rgba(255, 153, 51, 0.7) !important;
  color: inherit !important;
}

.has-text-alert {
  color: rgba(255, 153, 51, 1) !important;
}

.tag.is-danger, .button.is-danger {
  background-color: rgba(241, 70, 104, 0.5) !important;
  color: inherit !important;
}

.tag.is-info, .button.is-info {
  background-color: rgba(22, 125, 240, 0.5) !important;
  color: inherit !important;
}

.tag.is-primary, .button.is-primary {
  background-color: rgba(121, 87, 213, 0.5) !important;
  color: inherit !important;
}

.fixed-field .field{
  width: 200px;
  text-align: left;
}

.tab-content80 section.tab-content{
  width: 80% !important;
}

.flexed {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.flexed-right {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: right;
}

.flexed-left {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: left;
}

span.select {
  width: 100%;
}
.control select {
  width: 100%;
}

.text-success {
  color: rgba(72, 199, 116, 1) !important;
}

.text-primary {
  color: rgb(121,87,213) !important;
}

.text-warning {
  color: rgba(255, 221, 87, 1) !important;
}

.text-danger {
  color: rgba(241, 70, 104, 1) !important;
}

.text-default {
  color: rgba(0, 0, 0, 1) !important;
}

.is-clickable.is-active {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}

.leftal {
  text-align: left !important;
}

.rightal {
  text-align: right !important;
}

.w100 {
  width: 100%;
}

.m3 {
  margin: 3px;
}

.is-vcentered {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

h4.page-header {
  font-size: 30px !important;
  text-decoration: underline;
  padding-bottom: 10px;
}

h4.page-header-24 {
  font-size: 24px !important;
  text-decoration: underline;
  padding-bottom: 10px;
}

/*h4.page-header i {*/
/*  color: rgb(204, 51, 51);*/
/*}*/

/*div.control i {*/
/*  color: rgba(204, 51, 51, 0.5);*/
/*}*/

h4.sub-header {
  font-size: 22px !important;
  text-decoration: underline;
  padding-bottom: 10px;
}

.dash-plate {
  /*background-color: whitesmoke;*/
  /*width: 300px;*/
  border-radius: 5px;
}

.dash-plate .header {
  border-radius: 5px;
  width: 100%;
  padding: 5px;
}

.dash-plate .header.is-primary {
  background-color: rgba(121, 87, 213, 0.5) !important;
}

.dash-plate .header.is-info {
  background-color: rgba(22, 125, 240, 0.5) !important;
}

.dash-plate .header.is-success {
  background-color: rgba(72, 199, 116, 0.5) !important;
}

.dash-plate .header.is-warning {
  background-color: rgba(255, 221, 87, 0.5) !important;
}

.dash-plate .body {
  display: flex;
  flex-direction: row;
  align-content: space-between;
}

.dash-plate .body .side-icon {
  display: flex;
  align-items: center;
}

.dash-plate .body .side-info {
  color: #363636;
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: baseline;
  justify-content: center;
}

.dash-plate .body .today {
  font-size: 1rem !important;
}

a.navbar-item:hover{
  text-decoration: underline;
}

.field.plate {
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 5px;
}

.field.plate label {
  text-align: left;
  padding-left: 10px;
}

table td {
  vertical-align: middle !important;
}

._vue-flash-msg-_right-top {
  top: 60px !important;
  z-index: 999;
}

ul.horizontal {
  margin: 0 !important;
  padding: 0 !important;
  list-style: none !important;
}

ul.horizontal li {
  float: left;
  margin: 0 5px 0 0 !important;
}

ul.notifications {
  margin: 0 !important;
  padding: 10px !important;
  list-style: none !important;
}

ul.notifications li {
  display: flex;
  justify-content: left;
  padding: 5px;
}

ul.notifications li:nth-of-type(odd) {
  background-color: #fafafa;
}

ul.notifications li .time {
  width: 100px;
  font-style: italic;
}

ul.notifications li .text {
  width: 100%;
  text-align: left;
}

table.noborder td {
  border-width: 0 !important;
}

textarea {
  min-height: 10em !important;
}

.page-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  overflow:hidden;
  padding: 10px;
  background-color: #363636;
  color: rgb(255,255,255);
}

.risk-lvl {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.risk-lvl__arrow {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  transform-origin: 50% 19px;
}

.risk-lvl--1 .risk-lvl__arrow{
  transform: rotate(-115deg);
}

.risk-lvl--2 .risk-lvl__arrow{
  transform: rotate(-70deg);
}

.risk-lvl--3 .risk-lvl__arrow{
  transform: rotate(-20deg);
}

.risk-lvl--4 .risk-lvl__arrow{
  transform: rotate(20deg);
}

.risk-lvl--5 .risk-lvl__arrow{
  transform: rotate(70deg);
}

.risk-lvl--6 .risk-lvl__arrow{
  transform: rotate(115deg);
}

.strategies__risk {
  display: flex;
  justify-content: center;
}

.help-info {
  font-size: 15px;
}

.help-info h2{
  font-weight: bolder;
  font-size: 28px;
  text-decoration: underline;
  text-align: left;
  padding: 20px;
}
.help-info .article {
  text-align: left;
  padding: 10px;
  border-left: 1px dotted rgb(44,62,80);
}
.help-info .article ul {
  list-style: square inside;
}
.help-info .article ul li {
  padding: 5px;
}
</style>
