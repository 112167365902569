<template>
  <MoneyFormat :hide-subunits=subunits :value="amount" locale='ru' :currency-code="currency" />
</template>

<script>
import MoneyFormat from 'vue-money-format'

export default {
  name: "Money",
  props: ['amount', 'currency', 'subunits'],
  components: {MoneyFormat}
}
</script>

<style scoped>

</style>